<template>
  <div class="list-content-page">
    <div class="ym-title">饲料信息</div>
    <!-- 条件筛选 -->
    <div class="ym-panel-general">
     <el-form
        class="fl"
        :inline="true"
        :model="form"
      >
        <el-form-item prop="productName" label="产品名称：">
          <el-input
            v-model="form.productName"
            size="medium"
            placeholder="全部"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          class="ym-input-left"
          prop="batchNum"
          label="产品批次编号："
        >
          <el-input
            v-model="form.batchNum"
            size="medium"
            placeholder="全部"
            maxlength="50"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
         <el-button class="ym-input-left" type="primary" round size="medium" @click="submit">查询</el-button>
         <el-button plain round size="medium" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="ym-panel-table">
      <!-- 操作按钮 -->
      <div class="btns">
        <el-button
          type="primary"
          round
          icon="el-icon-plus"
          size="medium"
          @click="toAdd"
          >新增</el-button
        >
      </div>
      <!--表格-->
      <el-table
        :data="tableData"
        v-loading="loading"
        highlight-current-row
        class="ym-table"
        size="mini"
        stripe
        border
        :row-style="{height:'40px'}"
        :cell-style="{padding:'0px'}"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="feedNum"
          label="饲料批次编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productName"
          label="产品名称"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="batchNum"
          label="产品批次编号"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="feedCompanyName"
          label="饲料厂商"
          min-width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="feedName"
          label="饲料名称"
          min-width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="feedDate"
          label="饲料保质期"
          min-width="240"
          align="center"
        >
        <template slot-scope="scope">
            <i v-if="scope.row.feedDate" class="el-icon-date"></i>
            <span style="margin-left: 6px">{{ scope.row.feedDate }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="feedIntroduce"
          label="饲料介绍"
          min-width="100"
          align="center"
        >
          <template slot-scope="scope">
            
            <el-tooltip
              class="item"
              effect="light"
              :content="scope.row.feedIntroduce"
              placement="top"
            >
              <i class="iconfont iconchakan icon-btn"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="afcBatchFeedId" align="center" label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <template v-if="scope.row.chainFlag===1">
                <el-button type="text" @click="toSave(scope.row)">编辑</el-button>
                <el-button type="text" @click="deleteTable(scope.row)">删除</el-button>
            </template>
            <el-button type="text" @click="toGet(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 页码 -->
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      tableData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 40
      },
      form: {
        productName: '',
        batchNum: ''
      }
    }
  },
  computed: {},
  created() {
    this.getList()
  },
  methods: {
    toAdd() {
      this.$router.push({ path: '/listFeeds/saveFeed' }).catch(err => err)
    },
    indexMethod(index) {
      if (index + 1 < 10) {
        return '0' + (index + 1)
      }
      return index + 1
    },
    toGet(row) {
      this.$router.push({ path: '/listFeeds/getFeed', query: { id: row.afcBatchFeedId }})
    },
    toSave(row) {
      this.$router.push({ path: '/listFeeds/saveFeed', query: { id: row.afcBatchFeedId }})
    },
    pageSizeChange(val) {
      this.page.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.page.currentPage = val
      this.getList()
    },
    // 条件搜索
    submit() {
      this.getList()
    },
    // 条件重置
    reset() {
      this.form.batchNum = ''
      this.form.productName = ''
      this.getList()
    },
    // 删除列表
    deleteTable(row) {
      // 确认删除操作
      this.$confirm('确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除操作
        this.remove(row.afcBatchFeedId)
      }).catch(() => {
      })
    },
    async getList() {
      const params = {
        batchNum: this.form.batchNum,
        productName: this.form.productName,
        size: this.page.pageSize,
        current: this.page.currentPage
      }
      this.loading = true
      const { data } = await this.$http.feedInfor.listFeeds(params)
      if (data.code === '0') {
        this.tableData = data.data.records
        console.log('列表：', this.tableData)
        this.page.total = parseInt(data.data.total)
      } else {
        this.$message.error(data.msg || '获取列表失败')
      }
      this.loading = false
    },
    // 删除
    async remove(afcBatchFeedId) {
      const params = {
        afcBatchFeedId: afcBatchFeedId
      }
      this.loading = true
      const { data } = await this.$http.feedInfor.removeFeed(params)
      if (data.code !== '0') return this.$message.error(data.msg || '删除失败')
      else {
        this.$message.success(data.msg || '删除成功')
        if (this.tableData.length === 1) {
          this.page.currentPage -= this.page.currentPage
          this.getList()
        } else {
          this.getList()
        }
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scrope>
.list-content-page {
  .el-form-item {
      margin-bottom: 0;
    }
  .ym-panel-table {
    .icon-btn{
      font-size: 12px;
      color: #3E6DF5;
    }
  }
  .btns {
    text-align: right;
  }
  .ym-table {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
}
</style>
